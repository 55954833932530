import http from "./http-common"

class SurveyDataSurvey {
  
  get(id) {
    return http.get(`/survey/${id}`)
  }

  save(id, payload) {
    return http.put(`/survey/${id}`, payload)
  }

}

export default new SurveyDataSurvey()