<template>
 <header>
  <NavBar></NavBar>
  </header>

  <main id="main-content" class="lg:px-8 sm:px-3">
    <router-view/>
  </main>

  <footer>
  </footer>
</template>

<script>
import NavBar from '@/components/NavBar.vue'

export default {
  name: 'App',
  components: {
    NavBar
  }
}
</script>

<style>
#app {
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.bg-mdh {
  background-color: rgb(0, 56, 101);
}

#nav {
  padding-bottom: 30px;
}

#nav a {
  font-weight: normal;
  color: white;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#nav  a.dropdown-item {
  color: black !important;
}

.navbar-toggler-icon {
   background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

</style>
