import { createApp } from 'vue'
import App from './App.vue'

import PrimeVue from 'primevue/config'
import Dialog from 'primevue/dialog'
import Card from 'primevue/card'
import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Panel from 'primevue/panel';
import InputText from 'primevue/inputtext'
import Badge from 'primevue/badge'
import RadioButton from 'primevue/radiobutton'
import Fieldset from 'primevue/fieldset'
import Textarea from 'primevue/textarea'
import Message from 'primevue/message'
import ProgressSpinner from 'primevue/progressspinner';

import Ripple from 'primevue/ripple'
import StyleClass from 'primevue/styleclass'
import BadgeDirective from 'primevue/badgedirective'

import "primeflex/primeflex.css"

import router from './router'

const app = createApp(App)

app.use(router)

app.use(PrimeVue, {ripple: true})
app.component('Dialog', Dialog)
app.component('Card', Card)
app.component('Button', Button)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('Panel', Panel)
app.component('InputText', InputText)
app.component('Badge', Badge)
app.component('RadioButton', RadioButton)
app.component('Fieldset', Fieldset)
app.component('Textarea', Textarea)
app.component('Message', Message)
app.component('ProgressSpinner', ProgressSpinner)

app.directive('ripple', Ripple)
app.directive('styleclass', StyleClass)
app.directive('badge', BadgeDirective)

app.mount('#app')
