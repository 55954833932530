<template>
    <h1>In-Home COVID Vaccination Request Survey</h1>
    
     <Message severity="warn" :closable="false">The URL is incorrect to access a survey.</Message>
</template>

<script>

export default {
  name: 'NoSurveyId',
  components: {
  },
  
  data() {
      return {          
      }
  },
  
  methods: {
     
  },

  mounted() {
  }
}

</script>
