<template>
    <h1>In-Home COVID Vaccination Request Survey</h1>

    <div v-if="isLoading">
        <ProgressSpinner />
        <p>We are preparing your survey.</p>
    </div>
    
    <Message v-if="isNotFound" severity="warn" :closable="false">We could not find the survey you requested. </Message>    
    <Message v-if="isError" severity="error" :closable="false">There was an unexpected error.</Message>
    <Message v-if="isSuccess" severity="success" :closable="false">Your responses have been submitted.  Thank you for your feedback.</Message>
    <Message v-if="isAlreadyComplete" severity="warn" :closable="false">The requested survey has already been completed.  Thank you for your feedback.</Message>
        
    <div v-if="isLoaded">
        <p>The Minnesota Department of Health previously referred a request for an in-home COVID vaccination.  
            We are now following up with you to determine the outcome of that referral.</p>

        <p>This survey is for the experience with the vaccination request for:</p>
        <p class="my-4 text-xl"><strong>{{survey.firstName}} {{survey.lastName}}</strong></p>

        <hr/>

        <form @submit.prevent="handleSubmit(!v$.$invalid)">

            <Fieldset legend="1. Were you able to schedule an in-home vaccination for the individual who requested it? (required)" class="mt-4">
                <div class="field-radiobutton">
                        <RadioButton id="response1Y" name="reponse1" value="Yes" v-model="v$.survey.response1.$model" aria-label="Yes"
                            :class="{'p-invalid':v$.survey.response1.$invalid && submitted}" />
                        <label for="response1Y" :class="{'p-error':v$.survey.response1.$invalid && submitted}">Yes</label>
                </div>
                
                <div class="field-radiobutton">
                        <RadioButton id="response1N" name="reponse1" value="No" v-model="v$.survey.response1.$model" 
                            :class="{'p-invalid':v$.survey.response1.$invalid && submitted}"/>
                        <label for="response1N" :class="{'p-error':v$.survey.response1.$invalid && submitted}">No</label>
                </div>
                <div aria-live="polite">
                <small v-if="(v$.survey.response1.$invalid && submitted) || v$.survey.response1.$pending.$response" 
                class="p-error">{{v$.survey.response1.required.$message.replace('Value', 'Were you able to schedule an in-home vaccination for the individual who requested it')}}</small>
                </div>
            </Fieldset>

            <Fieldset legend="2. Were there any barriers in reaching the individual? (required)" class="mt-4">
                <div class="field-radiobutton">
                        <RadioButton id="response2Y" name="reponse2" value="Yes" v-model="v$.survey.response2.$model" aria-label="Yes"
                            :class="{'p-invalid':v$.survey.response2.$invalid && submitted}"/>
                        <label for="response2Y" :class="{'p-error':v$.survey.response2.$invalid && submitted}">Yes</label>
                </div>
                <div class="field-radiobutton">
                        <RadioButton id="response2N" name="reponse2" value="No" v-model="v$.survey.response2.$model" 
                            :class="{'p-invalid':v$.survey.response2.$invalid && submitted}"/>
                        <label for="response2N" :class="{'p-error':v$.survey.response2.$invalid && submitted}">No</label>                    
                </div>
                <div aria-live="polite">
                <small v-if="(v$.survey.response2.$invalid && submitted) || v$.survey.response2.$pending.$response" 
                class="p-error">{{v$.survey.response2.required.$message.replace('Value', 'Were there any barriers in reaching the individual')}}</small>
                </div>
            </Fieldset>

            <Fieldset legend="3. Please describe the barriers, if any, to reaching the individual." class="mt-4">
                <Textarea  v-model="v$.survey.response3.$model" :autoResize="true" rows="3" style="width:95%;" 
                    :class="{'p-invalid':v$.survey.response3.$invalid && submitted}"
                    aria-label="3. Please describe the barriers, if any, to reaching the individual."/>
                <div aria-live="polite">
                <small v-if="(v$.survey.response3.$invalid && submitted) || v$.survey.response3.$pending.$response" 
                    class="p-error"><br/>{{v$.survey.response3.maxLength.$message.replace('Value', 'X')}}</small>
                    </div>
            </Fieldset>

            <Fieldset legend="4. Please include any additional comments or positive outcomes you would like to share." class="mt-4">
                <Textarea  v-model="v$.survey.response4.$model" :autoResize="true" rows="3" style="width:95%;" 
                    :class="{'p-invalid':v$.survey.response4.$invalid && submitted}"
                    aria-label="4. Please include any additional comments or positive outcomes you would like to share."/>
                    <div aria-live="polite">
                    <small v-if="(v$.survey.response4.$invalid && submitted) || v$.survey.response4.$pending.$response" 
                    class="p-error"><br/>{{v$.survey.response4.maxLength.$message.replace('Value', 'X')}}</small>
                    </div>
            </Fieldset>

            <Button type="submit" label="Submit" class="mt-2" style="background:darkblue; border:darkblue;" />
            
        </form>
    </div>

</template>

<script>
import SurveyDataService from "@/services/SurveyDataService";
import { required, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: 'Home',
  components: {
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
      return {
          id: null,
          survey: {},
          submitted: false,
          isLoading: true,
          isNotFound: false,
          isAlreadyComplete: false,
          isError: false,
          isLoaded: false,
          isSuccess: false,
      }
  },

    validations() {
        return {
            survey: {
                response1: { required },
                response2: { required },
                response3: { maxLength: maxLength(500) },
                response4: { maxLength: maxLength(500) }
            }
        }
    },
  
  methods: {
      query(id) {
        console.log(id)
            this.isLoaded = false
            this.isNotFound = false
            this.isAlreadyComplete = false
            this.isError = false
          this.isLoading = true
                    this.survey = { }
          SurveyDataService.get(id)
          .then(response => {
              console.log(response)
            this.isLoading = false
            console.log(response.status)
            if(response.status === 200) {
                this.survey = response.data
                this.isLoaded = true
                if (this.survey.status === 'responded' || this.survey.status === 'complete') {
                    this.isAlreadyComplete = true
                    this.isLoaded = false
                }
                console.log(response.data)
            } 
            else if (response.status === 404) {
                this.isLoaded = false
                this.isNotFound = true
            } 
            })
            .catch(e => {
                console.log("errror!!!!")
            console.log(e)
            this.isLoading = false

            if (e.response.status === 404) {
                      this.isNotFound = true
            } else {

            this.isError = true
            }
            });
      },

       handleSubmit(isFormValid) {
            this.submitted = true;

            if (!isFormValid) {
                return;
            }

            console.log(this.survey)
            SurveyDataService.save(this.id, this.survey)
                .then(response => {
                    console.log(response)
                    this.isSuccess = true
                    this.isLoaded = false
                })


        },
     
  },

  mounted() {
      this.id = this.$route.params.surveyId
            this.query(this.id)
  }
}

</script>
