<template>

<div class="surface-overlay py-3 px-6 shadow-1 flex align-items-center justify-content-between relative lg:static" style="min-height: 80px">
    <img src="/logo-mdh-mn-h-blu_rgb.png" alt="Minnesota Department of Health Logo"
         height="45" class="mr-0 lg:mr-6">
</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
  },

  setup() {
    return {
    }
  },
})
</script>

<style scoped>
</style>